<template>
  <div class="content">
    <iframe scrolling="no" allowtransparency="true" frameborder="0"
      src="https://s.tradingview.com/embed-widget/ticker-tape/?locale=en#%7B%22symbols%22%3A%5B%7B%22proName%22%3A%22FOREXCOM%3ASPXUSD%22%2C%22title%22%3A%22S%26P%20500%22%7D%2C%7B%22proName%22%3A%22FOREXCOM%3ANSXUSD%22%2C%22title%22%3A%22US%20100%22%7D%2C%7B%22proName%22%3A%22FX_IDC%3AEURUSD%22%2C%22title%22%3A%22EUR%2FUSD%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3ABTCUSD%22%2C%22title%22%3A%22Bitcoin%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3AETHUSD%22%2C%22title%22%3A%22Ethereum%22%7D%5D%2C%22showSymbolLogo%22%3Atrue%2C%22colorTheme%22%3A%22dark%22%2C%22isTransparent%22%3Atrue%2C%22displayMode%22%3A%22adaptive%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A76%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22ticker-tape%22%2C%22page-uri%22%3A%22www.tradingview.com%2Fwidget%2Fticker-tape%2F%22%7D"
      style="box-sizing: border-box; display: block;  width: 100%"></iframe>
      <h2 class="h2-bold">Dashboard</h2>
    <Banner />
    <Stats />
    <div class="grid">
      <Team />
      <Wallets />
      <Benefits class="benefits"/>
    </div>
  </div>
</template>
<script>
import Benefits from './Benefits.vue';
import Wallets from './Wallets.vue';
import Team from './Team.vue';
import Referred from './Referred.vue';
import Banner from './Banner.vue';
import Stats from './Stats.vue';
import { mapState } from 'vuex';

export default {
  components: {
    Benefits,
    Wallets,
    Team,
    Referred,
    Banner,
    Stats,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  computed: {
    ...mapState('auth', ['user']),
  },
};
</script>

<style scoped>
.h2-bold{
  text-align: left;
}
iframe {
  height: 50px;
  display: flex !important;
  align-items: center;
}
.content {
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.grid{
  display: grid;
  gap: 32px;
  margin-bottom: 120px;
}

@media (min-width: 1000px) {
  .grid{
    grid-template-columns: repeat(2, 1fr);
  }
  .benefits{
    grid-column: 1/3;
  }
}
@media (min-width: 1600px) {
  .grid{
    grid-template-columns: repeat(3, 1fr);
  }
  .benefits{
    grid-column: unset;
  }
}

</style>
