<template>
  <div v-if="chartOptions">
    <vue-apex-charts
      type="donut"
      :options="chartOptions"
      :series="chartSeries"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import ApexCharts from "apexcharts";

export default {
  components: {
    VueApexCharts,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          width: "100%",
          height: 450,
          type: "donut",
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                },
                value: {
                  show: true,
                  formatter: function (val) {
                    return val + 0;
                  },
                  style: {
                    color: function ({ value }) {
                      return value === 0 ? "#4CAF50" : "#F44336";
                    },
                  },
                },
                total: {
                  show: true,
                },
              },
            },
          },
        },
        legend: {
          position: "bottom",
        },
        labels: [
          "Etherium",
          "BitcoinCash",
          "Bitcoin",
          "Eleven",
          "Lighcoin",
          "BitcoinCash",
        ],
      },
      chartSeries: [-2, 1, 6, -2, 1, 6],
    };
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    renderChart() {
      const chart = new ApexCharts(
        this.$refs.chartContainer,
        this.chartOptions
      );
      chart.render();
    },
  },
};
</script>
