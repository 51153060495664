<template>
  <section class="cards">
    <!-- <div class="cards__box cards__box--green">
      <img
        class="cards__box--icon"
        src="@/assets/images/b7-gris.png"
        alt=""
      />
      <div class="w-100 d-flex flex-column gap-2">
        <h6 v-if="user.range">
          {{ user.range.name }}
        </h6>
        <p class="cards__p">
          {{ formatCurrency(user.sold) }} of
          {{ formatCurrency(user.range.quantity) }}
        </p>
        <div class="d-flex gap-2 align-items-center">
          <b-progress
            class="w-100"
            :value="calcPercentage(user.sold, user.range.quantity)"
            max="100"
          ></b-progress>
          <p class="cards__text">
            {{ calcPercentage(user.sold, user.range.quantity) }}%
          </p>
        </div>
      </div>
    </div> -->
    <div class="cards__box bg-full">
      <img
        class=""
        src="@/assets/images/brand-copy.png"
        alt=""
      />
      <div>
        <h6>Total Inversión</h6>
        <h2 class="cards__price">${{ formatCurrency(actives) }}</h2>
      </div>
    </div>
    <div class="cards__box bg-full">
      <img
        src="@/assets/images/brand-copy.png"
        alt=""
      />
      <div class="">
        <h6>Active Memberships</h6>
        <h2 class="cards__price">
          {{ membershipsActive ? membershipsActive.length : 0 }}
        </h2>
      </div>
      <img class="cards__icon" src="@/assets/images/icon-card.svg" alt="" />
    </div>
    <div class="cards__box bg-full">
      <img
        src="@/assets/images/brand-copy.png"
        alt=""
      />
      <div class="">
        <h6>Referred users</h6>
        <h2 class="cards__price">{{ user.referrals ? user.referrals : 0 }}</h2>
      </div>
      <img class="cards__icon" src="@/assets/images/icon-card.svg" alt="" />
    </div>
    <div class="cards__box bg-full">
      <div class="w-100">
        <h6>Refer friends and earn!</h6>
        <Copy
          color="white"
          class=""
          :address="'https://app.upcademy.net/#/auth/signup/' + user.username"
        />
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions, mapState } from 'vuex';
export default {
  created() {
    this.getMembershipsActive().then(() => {
      this.membershipsActive.forEach((element) => {
        this.actives = element.membership.price + this.actives;
      });
    });
  },
  data() {
    return {
      actives: 0,
    };
  },
  methods: {
    ...mapActions('membership', ['getMembershipsActive']),
    calcPercentage(sold, quantity) {
      if (sold > 0) {
        let perce = (sold * 100) / quantity;

        return perce.toFixed(2);
      } else {
        return 0;
      }
    },
    formatCurrency(x) {
      if (x != null && x >= 0) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        return 0;
      }
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('membership', ['membershipsActive']),
  },
};
</script>
<style scoped>
.cards {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
}

.cards__box {
  position: relative;
  padding: 24px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
}
.cards h6 {
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  color: #e3c466;
  text-transform: uppercase;
  margin-bottom: 24px;
}
.cards__p {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  text-align: left;
  color: #fff;
  margin: 0;
}
.cards__text {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #e3c466;
  text-align: right;
  margin: 0;
}
.cards h2 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  color: #fff;
}

.cards__icon {
  width: 44px;
  height: 44px;
}
.cards__box--icon {
  position: absolute;
  left: 24px;
  top: 24px;
  height: 80px;
  opacity: 0.3;
}
.cards__box--green {
  background: linear-gradient(90deg, #2e894250 0%, #5ebb6650 100%);
  border: 1px solid #2E8942;
}
.cards__box--ocre {
  background: linear-gradient(90deg, #e1712650 0%, #f7981c50 100%);
  border: 1px solid #E17226;
}
.cards__box--blue {
  background: linear-gradient(90deg, #3b328150 0%, #3dbbed50 100%);
  border: 1px solid #5C4E9F;
}
.cards__box--purple {
  background: linear-gradient(90deg, #1d1a4c50 0%, #3b347f50 100%);
  border: 1px solid #3B347F;
}
@media (min-width:600px) {
  .cards{
    display: grid;
    grid-template-columns: repeat(2, 1fr) ;
  }
}

@media (min-width:1600px) {
  .cards{
    display: grid;
    grid-template-columns: repeat(4, 1fr) ;
  }
}
</style>

<style>
.cards .progress {
  height: 8px;
}

.cards .progress-bar {
  border-radius: 8px !important;
  background: linear-gradient(0deg, #815d2a 0%, #ffe087 100%);
}

</style>
