<template>
  <div class="h-100">
    <b-card title="Ranking referral" class="bg-ref h-100">
      <div class="row mt-5 mb-5 mx-auto justify-content-between align-items-end">
        <div class="col-3 left text-center g-0">
          <b-avatar
            :src="missing[2].image"
            size="7rem"
            v-if="missing[2] && missing[2].image != 'default.png'"
          />
          <b-avatar src="/images/left.png" size="7rem" v-else />
          <div class="mt-2">
            <p class="avatar__text">
              {{
                missing[2] && missing[2].referrals ? missing[2].referrals : 0
              }}
              referrals
            </p>
          </div>
        </div>
        <div class="col-6 center text-center g-0">
          <b-avatar
            :src="missing[0].image"
            size="10rem"
            v-if="missing[0] && missing[0].image != 'default.png'"
          />
          <b-avatar src="/images/center.png" size="10rem" v-else />
          <div class="mt-2">
            <p class="avatar__text">
              {{
                missing[0] && missing[0].referrals ? missing[0].referrals : 0
              }}
              referrals
            </p>
          </div>
        </div>
        <div class="col-3 right text-center g-0">
          <b-avatar
            :src="missing[1].image"
            size="9rem"
            v-if="missing[1] && missing[1].image != 'default.png'"
          />
          <b-avatar src="/images/right.png" size="9rem" v-else />
          <div class="mt-2">
            <p class="avatar__text">
              {{
                missing[1] && missing[1].referrals ? missing[1].referrals : 0
              }}
              referrals
            </p>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
export default {
  data() {
    return {
      missing: 0,
    };
  },
  methods: {
    ...mapActions('user', ['getMostRef']),
    getData() {
      this.loading = true;
      this.getMostRef().then((response) => {
        this.loading = false;
        this.missing = response;
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>
<style>
.left .b-avatar {
  border: white 3px solid !important;
  width: 40px !important;
  height: 40px !important;
}
.center .b-avatar {
  border: 5px solid #e3c87a;
  filter: drop-shadow(0px 4px 100px rgba(227, 200, 122, 0.4));
  width: 60px !important;
  height: 60px !important;
}
.right .b-avatar {
  border: 3px solid #e38800;
  filter: drop-shadow(0px 4px 100px rgba(227, 136, 0, 0.4));
  width: 40px !important;
  height: 40px !important;
}
.bg-ref {
  background: linear-gradient(90deg, #e1712650 0%, #f7981c50 100%) !important;
  border: 1px solid #E17226 !important;
}
.avatar__text {
  font-size: 12px;
}
@media (min-width: 350px) {
  .left .b-avatar {
    width: 50px !important;
    height: 50px !important;
  }
  .center .b-avatar {
    width: 70px !important;
    height: 70px !important;
  }
  .right .b-avatar {
    width: 50px !important;
    height: 50px !important;
  }
  .avatar__text {
    font-size: 14px;
  }
}
@media (min-width: 450px)and (min-width:900px) {
  .left .b-avatar {
    width: 60px !important;
    height: 60px !important;
  }
  .center .b-avatar {
    width: 80px !important;
    height: 80px !important;
  }
  .right .b-avatar {
    width: 60px !important;
    height: 60px !important;
  }
  .avatar__text {
    font-size: 16px;
  }
}
@media (min-width: 550px) and (min-width:1100px) {
  .left .b-avatar {
    border: white 6px solid !important;
    width: 100px !important;
    height: 100px !important;
  }
  .center .b-avatar {
    border: 10px solid #e3c87a;
    width: 140px !important;
    height: 140px !important;
  }
  .right .b-avatar {
    border: 6px solid #e38800;
    width: 100px !important;
    height: 100px !important;
  }
  .avatar__text {
    font-size: 18px;
  }
}
@media (min-width: 650px) and (min-width:1300px) {
  .left .b-avatar {
    width: 140px !important;
    height: 140px !important;
  }
  .center .b-avatar {
    width: 180px !important;
    height: 180px !important;
  }
  .right .b-avatar {
    width: 140px !important;
    height: 140px !important;
  }
  .avatar__text {
    font-size: 18px;
  }
}
@media (min-width: 1400px) {
  .left .b-avatar {
    border: white 10px solid !important;
    width: 150px !important;
    height: 150px !important;
  }
  .center .b-avatar {
    border: 18px solid #e3c87a;
    width: 200px !important;
    height: 200px !important;
  }
  .right .b-avatar {
    border: 10px solid #e38800;
    width: 150px !important;
    height: 150px !important;
  }
  .avatar__text {
    font-size: 16px;
  }
}
@media (min-width:1600px) {
  .left .b-avatar {
    width: 100px !important;
    height: 100px !important;
  }
  .center .b-avatar {
    width: 140px !important;
    height: 140px !important;
  }
  .right .b-avatar {
    width: 100px !important;
    height: 100px !important;
  }
  .avatar__text {
    font-size: 18px;
  }
}
</style>
