<template>
  <div>
    <div class="banner">
      <img
        class="banner__img"
        src="@/assets/images/dashboard.webp"
        alt="Snow" />
      <div class="banner__box">
        <div class="banner__left">
          <h2>
            Start earning
            <span>while </span><br /><span>you learn</span>
          </h2>
          <p>
            With Upcademy you can earn while you learn, you can make Staking
            or buy a membership in which you will get benefits and monthly
            earnings
          </p>
          <b-button
            variant="primary"
            class="col-10 mx-auto mx-md-0 col-md-6 mt-2"
            size="lg"
            :to="{ name: 'Membership' }">
            Ver planes disponibles
          </b-button>
        </div>
        <div class="banner__right">
          <img
            src="@/assets/images/dashboard-main.png"
            class="banner__right--img"
            alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.banner {
  position: relative;
  text-align: center;
  color: white;
  width: 100%;
  height: 494px;
}
.banner__img {
  width: 100%;
  height: 100%;
}

.banner__box {
  display: flex;
  position: absolute;
  padding: 4%;
  top: 0;
  align-items: center;
  height: 494px;
}

.banner__left {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.banner__left h2 {
  font-size: 46px;
  font-weight: 700;
  line-height: 55px;
  color: #f7991c;
  text-align: left;
  text-transform: uppercase;
}
.banner__left span {
  color: white;
  font-weight: 300;
}

.banner__left p {
  font-size: 22px;
  font-weight: 300;
  line-height: 26px;
  text-align: left;
}

.banner__right {
  width: 40%;
}
.banner__right img {
  width: 100%;
}

@media (max-width: 900px) {
  .banner {
    height: 650px;
  }
  .banner__box {
    padding: 20%;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .banner__left {
    order: 1;
    width: 100%;
  }
  .banner__left h2 {
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
  }
  .banner__left p {
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    text-align: center;
  }
  .banner__right {
    width: 100%;
  }
}


@media (max-width: 500px) {
  .banner__box {
    padding: 8%;
  }
}
</style>
